import React, { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { Translate } from '../translate';
import localizationConstants from 'shared/util/translation/constants';
import Button from '../form/button';

export interface ModalProps {
	show: boolean;
	handleClose: React.ReactEventHandler<{}>;
	className?: string;
	modalTitle?: string;
	modalTitle2?: string | JSX.Element;
	modalTitle3?: string | JSX.Element;
	dataTestId?: string;
	footer?: JSX.Element | boolean;
	loading?: boolean;
	children?: ReactNode;
}

const CustomModal: React.FC<ModalProps> = (props) => {
	return (
		<Modal
			show={props.show}
			onHide={() => false}
			onKeyDown={(e: any) => {
				if (e.key === "Escape" && e.keyCode === 27 && e.which === 27) {
					props.handleClose(e)
				}
			}}
			className={`${props.className || ''} fadeIn`}>
			{/* {props.modalTitle ? */}
			<Modal.Header>
				<Modal.Title>
					<Translate text={props.modalTitle || ""} />
					&nbsp;&nbsp;{props.modalTitle2}
					{props.modalTitle3}
					<Button className='modal-close-btn' onClick={props.handleClose}>
						<span>×</span><span className='sr-only'>
							<Translate text={localizationConstants.close} />
						</span>
					</Button>
				</Modal.Title>
			</Modal.Header>
			{/* } */}
			<Modal.Body>
				{props.children}
			</Modal.Body>
			{!!props.footer && (
				<Modal.Footer>
					{props.footer}
				</Modal.Footer>
			)}
		</Modal>
	)
}

export default CustomModal;
